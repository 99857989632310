<template>
  <main>
    <div class="min-h-screen flex items-center justify-center w-full">
      <div class="max-w-[25rem] py-12 w-full">
        <div class="flex justify-center mb-8">
          <MainLogo class="w-44 h-auto" />
        </div>

        <slot />
      </div>
    </div>
  </main>
</template>

<script setup>
const title = computed(() => {
  if (useRoute()?.meta?.title) {
    return `${typeof useRoute().meta.title === 'function' ? useRoute().meta.title() : useRoute().meta.title} | Attendu`;
  }

  return 'Attendu';
});
useSeoMeta({
  title,
  ogTitle: title,
});
</script>
